(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name people.controller:ExportCtrl
   *
   * @description
   *
   */
  angular
    .module('people')
    .controller('ExportCtrl', ExportCtrl);

  function ExportCtrl() {
    var vm = this;
    vm.ctrlName = 'ExportCtrl';
  }
}());
